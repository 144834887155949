import React, { useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import Gallery from 'react-grid-gallery';
import { useDeviceSize } from '../../Hooks/useDeviceSize';

const GalleryWrap = styled.div`
  overflow: hidden;
`;

const Project = ({ project }) => {
  const { isDesktop, isMobile } = useDeviceSize();

  useEffect(() => {
    if (project) {
      const textElement = document.getElementById(`projects_text_${project.id}`);
      textElement.innerHTML = project.text;
    }
  }, [project]);

  return (
    <Row>
      <Col lg={4} sm={12}>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={100} distance="30px">
          <div className="project-wrapper__text">
            <h3 className="project-wrapper__text-title">{project?.name}</h3>
            <span id={`projects_text_${project.id}`} />
          </div>
        </Fade>
      </Col>
      {project.images && project.images.length && (
        <Col lg={8} sm={12}>
          <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={100} distance="30px">
            <div className="project-wrapper__image">
              <GalleryWrap>
                <Gallery images={project.images} width="100%" backdropClosesModal />
              </GalleryWrap>
            </div>
          </Fade>
        </Col>
      )}
    </Row>
  );
};

export default Project;
