import React, { useState, useEffect } from 'react';
import LandingPage from './LandingPage/LandingPage';
import Services from './Services/Services';
import ContactForm from './ContactForm/ContactForm';
import Projects from './Projects/Projects';
import Partners from './Partners/Partners';
import Certificates from './Certificates/Certificates';

import Prices from './Pricing/Pricing';

import Contant from './Contact/Contact';

import { PortfolioProvider } from '../context/context';

import { aboutData, projectsData, kontaktData } from '../mock/data';

const transformReduceArr = (dataArr) => dataArr.reduce((acc, cur) => {
  if (acc.hasOwnProperty(cur.category)) {
    acc[cur.category].push(cur);
    return acc;
  }
  acc[cur.category] = [cur];
  return acc;
}, {});

function App() {
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [contact, setContact] = useState({});

  const [cms, setCms] = useState({});

  useEffect(() => {
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setContact({ ...kontaktData });
  }, []);

  useEffect(() => {
    fetch('https://admin.kominictviplzensko.cz/api/articles')
      .then((resp) => resp.json())
      .then((data) => {
        const transformedData = transformReduceArr(data);

        setCms({ ...transformedData });
      });
  }, []);

  return (
    <PortfolioProvider value={{
      cms, about, projects, contact,
    }}
    >
      <LandingPage />
      <Services />
      <ContactForm />
      <Projects />
      <Prices />
      <Certificates />
      <Partners />
      <Contant />
    </PortfolioProvider>
  );
}

export default App;
