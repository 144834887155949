import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import { Download } from 'react-bootstrap-icons';
import Title from '../Title/Title';
import { IconWrap, DocumentButtonWrap } from './styles';

import PortfolioContext from '../../context/context';
import Osvedceni from '../../images/osvedceni.pdf';
import VyucniList from '../../images/Vyucni_list.png';
import { useDeviceSize } from '../../Hooks/useDeviceSize';

const Certificates = () => {
  const { cms } = useContext(PortfolioContext);
  const { isDesktop, isMobile } = useDeviceSize();

  const { documents } = cms;

  return (
    <section id="certification">
      <Container id="reference">
        <div className="project-wrapper">
          <Title title="Certifikace" />
          {/* {documents && documents[0]?.name} */}

          <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
            <Row>
              <Col md={6} sm={12}>
                {Osvedceni && (
                  <DocumentButtonWrap>
                    <span className="d-flex mt-3">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-btn cta-btn--hero"
                        href={Osvedceni}
                      >
                        <IconWrap>
                          <Download />
                        </IconWrap>
                        Osvědčení o získání profesní kvalifikace
                      </a>
                    </span>
                  </DocumentButtonWrap>
                )}
              </Col>
              <Col md={6} sm={12}>
                {VyucniList && (
                  <DocumentButtonWrap>
                    <span className="d-flex mt-3">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-btn cta-btn--hero"
                        href={VyucniList}
                      >
                        <IconWrap>
                          <Download />
                        </IconWrap>
                        Výuční list
                      </a>
                    </span>
                  </DocumentButtonWrap>
                )}

                {/* <span>
                  o podmínkách požární bezpečnosti při provozu komínů, kouřovodů a spotřebičů paliv
                </span> */}
              </Col>
            </Row>
          </Fade>
        </div>
      </Container>
    </section>
  );
};

export default Certificates;
