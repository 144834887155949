import React, { useContext, useState, useEffect } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Navbar, Nav } from 'react-bootstrap';
import styled from 'styled-components';

import { Wrapper, NavigationWrapper } from './styles';
import { useDeviceSize } from '../../Hooks/useDeviceSize';

import 'bootstrap/dist/css/bootstrap.min.css';

const StyledNavbar = styled(Navbar)`
  .navbar-toggler {
    border: 2px solid black !important;
  };
  justify-content: flex-end;
`;

const StyledNav = styled(Nav)`
  a {
    color: black !important;
    font-weight: 600;
    margin: 5px;
  }
`;

const NavbarLinks = ({ desktop }) => {
  const { isDesktop, isMobile } = useDeviceSize();

  return (
    <NavigationWrapper desktop={isDesktop}>
      <StyledNavbar bg="white" expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <StyledNav className="mr-auto">
            <AnchorLink href="#sluzby">Služby</AnchorLink>
            <AnchorLink href="#projects">Realizace</AnchorLink>
            <AnchorLink href="#pricing">Ceník</AnchorLink>
            <AnchorLink href="#certification">Certifikáty</AnchorLink>
            <AnchorLink href="#partners">Partneři</AnchorLink>

            <AnchorLink href="#contact">Kontakt</AnchorLink>
          </StyledNav>
        </Navbar.Collapse>
      </StyledNavbar>
    </NavigationWrapper>
  );
};

export default NavbarLinks;
