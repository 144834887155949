import styled from 'styled-components';

export const Wrapper = styled.div``;

export const SkillsWrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Document = styled.div`
  max-width: 800px;
  text-align: left;

  h2 {
    font-weight: 800;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  h3 {
    font-weight: 800;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  table {
    table-layout: auto;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  tbody {
    width: 100%;
  }

  tr {
    border-bottom: 1px solid grey;
    width: 100%;
  }
  td {
    text-align: left;
    padding: 5px;
    padding-bottom: 2px;
  }
  .tdPrice {
    text-align: right;
  }
`;

export const BolderP = styled.p`
  font-weight: 600;
`;
