import React, { useContext, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';

import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';
import { useDeviceSize } from '../../Hooks/useDeviceSize';

const Services = () => {
  const { isDesktop, isMobile } = useDeviceSize();
  const { about, cms } = useContext(PortfolioContext);
  const { img } = about;
  const { services } = cms;

  useEffect(() => {
    if (services) {
      const text_content = document.getElementById('text_content_services');
      text_content.innerHTML = services[0]?.text;
    }
  }, [services]);

  return (
    <section id="services">
      <div id="design-bg">
        <Container id="sluzby">
          <Title title="Naše služby" />
          <Row className="about-wrapper">
            <Col md={6} sm={12}>
              <Fade left={isDesktop} bottom={isMobile} duration={600} delay={0} distance="30px">
                <div className="about-wrapper__info">
                  <span id="text_content_services" />
                </div>
              </Fade>
            </Col>
            <Col md={6} sm={12}>
              <Fade bottom duration={600} delay={0} distance="30px">
                <div className="about-wrapper__image">
                  <AboutImg alt="profile picture" filename={img} />
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default Services;
