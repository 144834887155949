import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';

import Title from '../Title/Title';
import PortfolioContext from '../../context/context';

import { PartnerImageWrap, StyledGrid, StyledSpan } from './styles';

const transformImages = (galleryArr) => galleryArr.map((src) => ({ src, thumbnail: src }));

const transformReferences = (projectsArr) => projectsArr.map((reference) => ({ ...reference, images: transformImages(reference.gallery) }));

const Projects = () => {
  const { cms } = useContext(PortfolioContext);
  const { partners } = cms;
  const [localData, setLocalData] = useState([]);

  useEffect(() => {
    if (partners) {
      setLocalData(transformReferences(partners)[0]);
    }
  }, [partners]);

  useEffect(() => {
    // const text_content = document.getElementById('partners_inner_html');
    // text_content.innerHTML = localData?.text;
  }, [localData]);

  return (
    <section id="partners">
      <Container id="reference">
        <div className="project-wrapper">
          <Title title={localData.name} />
          <StyledSpan id="partners_inner_html" />
          <Grid container spacing={3}>
            {localData?.gallery?.map((image) => (
              <StyledGrid item lg={3} md={6} sm={6} xs={12}>
                <a href={image.link} target="_blank" rel="noreferrer">
                  <PartnerImageWrap width="20px" backgroundImg={image.src}>
                    {/* <div style={{ backgroundImage: { image } }} /> */}
                  </PartnerImageWrap>
                </a>
              </StyledGrid>
            ))}
          </Grid>
        </div>
      </Container>
    </section>
  );
};

export default Projects;
