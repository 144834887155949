import styled from 'styled-components';

export const NavigationWrapper = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  a {
    color: #000;
    text-decoration: none;
    font-weight: 600;
    margin-top: 10px;
    width: 100%;
  }

  ${({ desktop }) => (desktop
    ? `
			align-items: center;
			display: flex;


			a {
					margin-right: 1rem;

					&:last-child {
							margin-right: unset;
					}
			}
		`
    : `
			padding: 3rem;
			display: flex;
			flex-direction: column;

			a {
					margin-bottom: 1rem;

					&:last-child {
							margin-bottom: unset;
					}
			}
	`)}
`;
