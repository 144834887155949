import styled from 'styled-components';

export const IconWrap = styled.span`
  margin: 5px;
  font-weight: 900;
`;

export const DocumentButtonWrap = styled.span`
  a {
    width: 100%;
  }
`;
