import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-scroll';

import Fade from 'react-reveal/Fade';
import { Facebook, Instagram } from 'react-bootstrap-icons';
import Title from '../Title/Title';
import PortfolioContext from '../../context/context';
import FooterImg from '../Image/FooterImg';

const Contact = () => {
  const { contact } = useContext(PortfolioContext);
  const { img, defaultCenter } = contact;

  return (
    <footer className="footer navbar-static-bottom" id="contact">
      <Container>
        <Title title="Kontakt" />

        <Fade bottom duration={1000} delay={200} distance="30px">
          <span className="back-to-top">
            <Link to="landingPage" smooth duration={1000}>
              <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
            </Link>
          </span>

          <Row>
            <Col md={6} sm={12}>
              <p>
                <b>Ing. Michal Kožíšek</b>
              </p>
              <p> IČ: 02006634 </p>
              <p> DIČ: CZ8708251310</p>
              <p> Zapsán v živnostenském rejstříku</p>
              <p> Adresa: Příchovice 288, Příchovice 33401 </p>
            </Col>

            <Col md={6} sm={12}>
              <div className="secondColWrap">
                <p>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <a href="https://www.facebook.com/profile.php?id=61555416252982"><Facebook size={20} /></a>
                  &nbsp;
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <a href="https://www.instagram.com/kominickyserviskozisek/"><Instagram size={20} /></a>
                </p>
                <p>
                  <b>Kominictví Plzeňsko </b>
                </p>
                <p>
                  Telefon:
                  {' '}
                  <a href="tel:+420731767412">+420 731 767 412</a>
                </p>
                <p>
                  Email:
                  {' '}
                  <a href="mailto:kominictviplzensko@gmail.com">kominictviplzensko@gmail.com</a>
                </p>
                <p>
                  WEB:
                  {' '}
                  <a href="http://kominictviplzensko.cz/" target="_blank" rel="noreferrer">
                    kominictviplzensko.cz
                  </a>
                </p>
                <p>
                  Bankovní spojení pro plátce DPH
                  <br />
                  {' '}
                  s.r.o IČ:17810752 číslo účtu: 2002406484/2010
                </p>
                <p>
                  Bankovní spojení pro plátce DPH
                  <br />
                  {' '}
                  fyzická osoba IČ: 02006634 číslo účtu:2202406462/2010
                </p>
                <p>
                  Bankovní spojení pro neplátce DPH
                  <br />
                  {' '}
                  Fyzická osoba IČ: 10955852 číslo účtu: 2202406462/2010
                </p>
                <p>
                  <b>Adresa:</b>
                  {' '}
                  Heyrovského 410/28, Plzeň
                  {' '}
                </p>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={6} sm={12}>
              <p> Region působení Kominictví Plzeňsko </p>

              <FooterImg alt="profile picture" filename={img} />
            </Col>
            <Col md={6} sm={12}>
              <p> Pracovní doba dle sjednaných zakázek.</p>
              <p>Pracujeme Karlovy Vary, Praha, Písek, České Budějovice</p>
              <p>Základny: PLZEŇ, KLATOVY, ROKYCANY</p>
            </Col>
          </Row>
        </Fade>
        <hr />
        <p className="footer__text">
          © Kominictví Plzeňsko - Michal Kožíšek
          {' '}
          {new Date().getFullYear()}
        </p>

        {/* {isEnabled && <GithubButtons />} */}
      </Container>
    </footer>
  );
};

export default Contact;
