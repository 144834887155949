import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import Navigation from '../Navigation/Navigation';
import { useDeviceSize } from '../../Hooks/useDeviceSize';

import razitko from '../../images/razitko.jpeg';
import {
  RazitkoImage,
  RazitkoWrap,
  ButtonsWrap,
  ButtonsItem,
  RazitkoItem,
} from './styles';

const StyledBodyContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`;

const StyledSection = styled.section`
  padding: 0px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const LandingPage = () => {
  const { isDesktop, isMobile } = useDeviceSize(false);

  return (
    <>
      <section id="landingPage">
        <Container>
          <Navigation />
        </Container>
      </section>
      <StyledSection
        id="hero"
        className="jumbotron"
      >
        <StyledBodyContainer className="main container">
          <h1 className="hero-title">
            Kominický servis Kožíšek
            <p className="text-color-main">
              profesionální kominické služby v západních Čechách
            </p>
          </h1>
          <ButtonsWrap>
            <ButtonsItem>
              <Fade
                left={isDesktop}
                bottom={isMobile}
                duration={500}
                delay={500}
                distance="30px"
              >
                <p className="hero-cta">
                  <span className="cta-btn cta-btn--hero">
                    Objednávky:
                  </span>
                </p>
              </Fade>
              <Fade
                left={isDesktop}
                bottom={isMobile}
                duration={500}
                delay={700}
                distance="30px"
              >
                <p className="hero-cta">
                  <span className="cta-btn cta-btn--hero">
                    <a href="tel:+420608312015">
                      +420 608 312 015
                    </a>
                  </span>
                </p>
              </Fade>
              <Fade
                left={isDesktop}
                bottom={isMobile}
                duration={500}
                delay={900}
                distance="30px"
              >
                <p className="hero-cta">
                  <span className="cta-btn cta-btn--hero">
                    Technik:
                  </span>
                </p>
              </Fade>
              <Fade
                left={isDesktop}
                bottom={isMobile}
                duration={500}
                delay={1100}
                distance="30px"
              >
                <p className="hero-cta">
                  <span className="cta-btn cta-btn--hero">
                    <a href="tel:+420731767412">
                      +420 731 767 412
                    </a>
                  </span>
                </p>
              </Fade>
              {/* <img src={razitko} /> */}
              <Fade
                left={isDesktop}
                bottom={isMobile}
                duration={1000}
                delay={1300}
                distance="30px"
              >
                <p className="hero-cta">
                  <span className="cta-btn cta-btn--hero">
                    <Link
                      to="sluzby"
                      smooth
                      duration={1000}
                    >
                      Co nabízíme
                    </Link>
                  </span>
                </p>
              </Fade>
            </ButtonsItem>
            <RazitkoItem>
              <RazitkoImage src={razitko} />
            </RazitkoItem>
          </ButtonsWrap>
          <RazitkoWrap>
            <RazitkoImage src={razitko} />
          </RazitkoWrap>
        </StyledBodyContainer>
      </StyledSection>
    </>
  );
};

export default LandingPage;
