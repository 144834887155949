import { useState, useEffect } from 'react';

export function useDeviceSize() {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    handleResize();
  }, []);

  const handleResize = () => {
    if (!window) {
      return;
    }

    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  console.log({ isMobile });

  return { isDesktop, isMobile };
}
