import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import {
  Document, SkillsWrapper, Wrapper, BolderP,
} from './styles';
import { useDeviceSize } from '../../Hooks/useDeviceSize';

const Prices = () => {
  const { isDesktop, isMobile } = useDeviceSize();
  const { cms } = useContext(PortfolioContext);
  const { prices } = cms;

  useEffect(() => {
    if (prices) {
      const text_content = document.getElementById('text_content_prices');
      text_content.innerHTML = prices[0]?.text;
    }
  }, [prices]);

  return (
    <section id="pricing">
      <Container>
        <div className="project-wrapper">
          <Wrapper id="cenik">
            <Title title="Cen&iacute;k" />
            <SkillsWrapper>
              <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={100} distance="30px">
                <Document>
                  <span id="text_content_prices" />
                  {/* <div className="project-wrapper__text">
                    <h2 className="project-wrapper__text-title">
                      Cen&iacute;k služeb - Kominictv&iacute; Kož&iacute;&scaron;ek
                    </h2>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <p>
                              Kontrola, či&scaron;těn&iacute; a zpr&aacute;va o proveden&eacute;
                              kontrole spalinov&eacute; cesty
                              <br />
                              Tuh&aacute; paliva, plynn&aacute; paliva
                            </p>
                          </td>

                          <td className="tdPrice">
                            <p>400 Kč</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <br />
                            <BolderP>Nab&iacute;dka pro obce nebo skupiny</BolderP>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>
                              Při počtu objektů vy&scaron;&scaron;&iacute;m než 10 ks - cena za
                              objekt
                            </p>
                          </td>

                          <td className="tdPrice">
                            <p>370 Kč</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>
                              Při počtu objektů vy&scaron;&scaron;&iacute;m než 20 ks - cena za
                              objekt
                            </p>
                          </td>

                          <td className="tdPrice">
                            <p>340 Kč</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p>
                      Prov&aacute;d&iacute;me kotrolu spalinov&yacute;ch cest pomoc&iacute;
                      kominick&eacute;ho vybaven&iacute; (kom&iacute;nov&aacute; kamera, strojek,
                      &scaron;tětka a&nbsp;běžně použ&iacute;van&eacute; vybaven&iacute;). Při
                      použit&iacute; kominick&eacute; kamery se cena nenavy&scaron;uje.
                    </p>
                    <h2 className="project-wrapper__text-title">
                      Vložkov&aacute;n&iacute; kom&iacute;nov&yacute;ch průduchů
                    </h2>
                    <h3 className="project-wrapper__text-sub-title">Tuh&aacute; paliva</h3>
                    <p>Cena je uvedena pro ohebnou vložku z nerez oceli o průměru 150 mm</p>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <p>1 metr pruduchu (vložka)</p>
                          </td>
                          <td className="tdPrice">
                            <p>1 350 Kč</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Za&uacute;stěn&iacute;</p>
                          </td>
                          <td className="tdPrice">
                            <p>1 300 Kč</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>V&yacute;choz&iacute; revize</p>
                          </td>
                          <td className="tdPrice">
                            <p>1 100 Kč</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Stavebn&iacute; materi&aacute;l a drobn&eacute; doplňky</p>
                          </td>
                          <td className="tdPrice">
                            <p>1 000 Kč</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h3 className="project-wrapper__text-sub-title">Plynn&aacute; paliva</h3>
                    <p>Cena je uvedena pro ohebnou vložku z nerez oceli o průměru 110 mm</p>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <p>1 metr pruduchu (vložka)</p>
                          </td>
                          <td className="tdPrice">
                            <p>1 100 Kč</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Za&uacute;stěn&iacute;</p>
                          </td>
                          <td className="tdPrice">
                            <p>1 300 Kč</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>V&yacute;choz&iacute; revize</p>
                          </td>
                          <td className="tdPrice">
                            <p>1 100 Kč</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Stavebn&iacute; materi&aacute;l a drobn&eacute; doplňky</p>
                          </td>
                          <td className="tdPrice">
                            <p>1 000 Kč</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <p>Dopojen&iacute; kouřovodu a revize</p>
                          </td>
                          <td className="tdPrice">
                            <p>1 800 Kč</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p>Nejsem pl&aacute;tce DPH.</p>
                  </div> */}
                </Document>
              </Fade>
            </SkillsWrapper>
          </Wrapper>
        </div>
      </Container>
    </section>
  );
};

export default Prices;
