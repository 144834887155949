import React, { useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import {
  Container, Row, Col, Form, Button,
} from 'react-bootstrap';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';

import Title from '../Title/Title';
import { useDeviceSize } from '../../Hooks/useDeviceSize';
import './styles.css';

const sizes = { full: 'full', half: 'half' };

const sendingStages = {
  virgin: 'virgin',
  sending: 'sending',
  error: 'error',
  success: 'success',
};

const ContactForm = () => {
  const { isDesktop, isMobile } = useDeviceSize();
  const [sendingMail, setSendingMail] = useState(sendingStages.virgin);

  useEffect(() => {
    emailjs.init('user_9giV7b6yaFmgdvDwFQo1X');
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    setSendingMail(sendingStages.sending);

    const checkboxesCheckedValues = checkboxes.map((item) => {
      const targetEl = e.target.elements[`checkbox_${item.id}`];

      if (targetEl.checked) {
        return targetEl.labels[0].innerHTML;
      }
    });

    const filteredArr = checkboxesCheckedValues.filter(Boolean);

    const connecterString = filteredArr.join(',');

    emailjs
      .send('service_xov6i1g', 'template_u6xkvjq', {
        name: e.target.elements.name.value,
        services: connecterString,
        email: e.target.elements.email.value,
        tel: e.target.elements.tel.value,
        details: e.target.elements.details.value,
      })
      .then(
        (result) => {
          console.log(result.text);
          toast(' ✔️ Posláno ', {
            type: 'success',
            position: 'top-left',
          });
          setSendingMail(sendingStages.success);
          document.getElementById('myForm').reset();
        },
        (error) => {
          toast(' ❌ Error ', {
            type: 'error',
            position: 'top-left',
          });
          console.log(error.text);
          setSendingMail(sendingStages.error);
        },
      );

    emailjs
      .send('service_xov6i1g', 'template_o3cmj1w', {
        name: e.target.elements.name.value,
        services: connecterString,
        email: e.target.elements.email.value,
        tel: e.target.elements.tel.value,
        details: e.target.elements.details.value,
      });
  };

  const checkboxes = [
    {
      title: 'Pravidelná kontrola',
      size: sizes.half,
      id: 1,
    },
    { title: 'Revize', size: sizes.half, id: 2 },
    {
      title: 'Dopojení spotřebiče',
      size: sizes.half,
      id: 3,
    },
    {
      title: 'Vložkování',
      size: sizes.half,
      id: 4,
    },
    {
      title: 'Frézování',
      size: sizes.half,
      id: 5,
    },
    {
      title: 'Rekonstrukce',
      size: sizes.half,
      id: 6,
    },
    {
      title: 'Realizace zděného komína',
      size: sizes.full,
      id: 7,
    },
    {
      title: 'Realizace nerezového komína',
      size: sizes.full,
      id: 8,
    },
    {
      title: 'Prodej a instalace kamen',
      size: sizes.full,
      id: 9,
    },
    {
      title: 'Prodej a instalaci krbovych vlozek',
      size: sizes.full,
      id: 10,
    },
  ];

  const renderBtnLabel = () => {
    console.log({ sendingMail });
    switch (sendingMail) {
      case sendingStages.virgin:
        return 'Poslat';
      case sendingStages.sending:
        return 'Posílám...';
      case sendingStages.success:
        return 'Odesláno';
      case sendingStages.error:
        return 'Error';
      default:
        return 'default';
    }
  };

  const getIsBtnDsabled = () => {
    switch (sendingMail) {
      case sendingStages.virgin:
      case sendingStages.error:
        return false;
      case sendingStages.sending:
      case sendingStages.success:
        return true;
      default:
        return false;
    }
  };

  const getButtonClassname = () => (getIsBtnDsabled() ? 'formSent' : '');

  const isDisabled = getIsBtnDsabled();
  const buttonClassName = getButtonClassname();

  return (
    <section id="form">
      <Container id="sluzby">
        <Title title="Kontaktní formulář" />
        <Row className="about-wrapper justify-content-md-center">
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={600} delay={0} distance="30px" />

            <Form id="myForm" style={{ textAlign: 'left' }} onSubmit={sendEmail}>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Label>Zájem o služby</Form.Label>
                <Row className="justify-content-center">
                  {checkboxes.map((item) => (
                    <Col md={item.size === sizes.half ? 6 : 12} sm={12}>
                      <Form.Check
                        type="checkbox"
                        label={item.title}
                        name={`checkbox_${item.id}`}
                        id={`default-checkbox-${item.id}`}
                        disabled={isDisabled}
                      />
                    </Col>
                  ))}
                </Row>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label className="required">Jméno a příjmení</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Zadejte jméno a příjmení"
                  name="name"
                  required
                  disabled={isDisabled}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="required">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Zadejte email"
                  name="email"
                  required
                  disabled={isDisabled}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="required">Telefon</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Zadejte telefon"
                  name="tel"
                  required
                  disabled={isDisabled}
                  className="required"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Doplnující informace</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Doplňte dodatečné detaily"
                  name="details"
                  disabled={isDisabled}
                />
              </Form.Group>

              <Button className={buttonClassName} variant="primary" type="submit" size="lg" disabled={isDisabled}>
                {renderBtnLabel()}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactForm;

export const CheckboxesWrap = styled.div`
  display: flex;
  gap: 10px;
`;
