import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import PortfolioContext from '../../context/context';
import ProjectComponent from './ProjectComponent';

const transformImages = (galleryArr) => galleryArr.map((src) => ({ src, thumbnail: src }));

const transformReferences = (projectsArr) => projectsArr.map((reference) => ({ ...reference, images: transformImages(reference.gallery) }));

const Projects = () => {
  const { cms } = useContext(PortfolioContext);
  const { references } = cms;
  const [localData, setLocalData] = useState([]);

  useEffect(() => {
    if (references) {
      const newReferences = transformReferences(references);
      setLocalData(newReferences);
    }
  }, [references]);

  return (
    <section id="projects">
      <Container id="reference">
        <div className="project-wrapper">
          <Title title="Reference" />
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="cta-btn cta-btn--hero"
                href="https://www.firmy.cz/detail/12846235-kominictvi-kozisek-plzen.html#hodnoceni"
              >
                <img src="https://www.firmy.cz/img-stars/light-12846235.svg" alt="Kominictví Kožíšek na Firmy.cz" />
              </a>
            </div>
          </div>
          {localData && localData.map((project) => <ProjectComponent project={project} />)}
        </div>
      </Container>
    </section>
  );
};

export default Projects;
